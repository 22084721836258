<template>
    <v-container>
      <v-row justify="center" align="center"  style="min-height: 100vh;">
        <v-col cols="12" sm="8" md="6">
          <v-card elevation="10">
            <v-card color="#1867C0" elevation="15">
                <v-card-title class="headline" >Register</v-card-title>

            </v-card>
            <v-card-text>
              <v-form ref="form" v-model="valid">
                <v-text-field
                  v-model="name"
                  label="Name"
                  :rules="nameRules"
                  required
                  clearable
                  variant="outlined"

                ></v-text-field>
                <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="emailRules"
                  required
                  clearable
                  variant="outlined"

                ></v-text-field>
                <v-text-field
                  v-model="password"
                  label="Password"
                  :rules="passwordRules"
                  type="password"
                  required
                  clearable
                  variant="outlined"

                ></v-text-field>
                <v-text-field
                  v-model="confirmPassword"
                  label="Confirm Password"
                  :rules="confirmPasswordRules"
                  type="password"
                  required
                  clearable
                  variant="outlined"

                ></v-text-field>
                <v-text-field
                  v-model="phone"
                  label="Phone"
                  :rules="phoneRules"
                  required
                  clearable
                  variant="outlined"

                ></v-text-field>
                <v-text-field
                  v-model="address"
                  label="Address"
                  :rules="addressRules"
                  required
                  clearable
                  variant="outlined"

                ></v-text-field>
                <v-text-field
                  v-model="dob"
                  label="Date of Birth"
                  :rules="dobRules"
                  type="date"
                  required
                  clearable
                  variant="outlined"

                ></v-text-field>
                <v-btn
                  @click="register"
                  :disabled="!valid"
                  color="primary"
                  class="mt-4"
                >
                  Register
                </v-btn>
              </v-form>
              <!-- Additional prompt for existing users -->
              <v-row justify="center" class="mt-4">
                <v-col>
                  <p class="text-center">
                    Already registered? 
                    <span @click="goToLogin" class="login-link">Login</span>
                  </p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
  
      <!-- Snackbar for success or error messages -->
      <v-snackbar v-model="snackbar" :timeout="3000" top>
        {{ snackbarMessage }}
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'UserRegister',
    data() {
      return {
        valid: false,
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        phone: '',
        address: '',
        dob: '',
        snackbar: false,
        snackbarMessage: '',
        // Validation rules
        nameRules: [(v) => !!v || 'Name is required'],
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        passwordRules: [
          (v) => !!v || 'Password is required',
          (v) => v.length >= 6 || 'Password must be at least 6 characters long',
        ],
        confirmPasswordRules: [
          (v) => v === this.password || 'Passwords must match',
        ],
        phoneRules: [
          (v) => !!v || 'Phone is required',
          (v) => /^\d+$/.test(v) || 'Phone must be a number',
        ],
        addressRules: [(v) => !!v || 'Address is required'],
        dobRules: [(v) => !!v || 'Date of Birth is required'],
      };
    },
    methods: {
      async register() {
        if (this.$refs.form.validate()) {
          try {
            await this.$store.dispatch('register', {
              name: this.name,
              email: this.email,
              password: this.password,
              phone: this.phone,
              address: this.address,
              dob: this.dob,
            });
            this.snackbarMessage = 'Registration successful!';
            this.snackbar = true;
            this.$router.push('/login');
          } catch (error) {
            this.snackbarMessage = error.response.data.message || 'Registration failed!';
            this.snackbar = true;
          }
        }
      },
      goToLogin() {
        this.$router.push('/login'); // Navigate to the login page
      },
    },
  };
  </script>
  
  <style scoped>
  .v-card {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  .login-link {
    color: blue; /* Change color as needed */
    cursor: pointer; /* Indicate that it is clickable */
    text-decoration: underline; /* Underline for a link effect */
  }
  </style>
  