<template>
  <v-container>
    <v-btn @click="openDialog" color="primary">Add Home Section</v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ isEdit ? 'Edit Home Section' : 'Add Home Section' }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <!-- Welcome Message -->
            <v-textarea
              v-model="home.welcomeMessage"
              label="Welcome Message"
              required
            ></v-textarea>

            <!-- Featured Products Selection -->
            <v-card>
              <v-card-text>
                <h3>Featured Products</h3>
                <v-chip-group
                  v-model="home.featuredProducts"
                  multiple
                  label="Featured Products"
                  class="mt-2"
                >
                  <v-chip
                    v-for="product in products"
                    :key="product.id"
                    @click="toggleProduct(product.id)"
                    :input-value="home.featuredProducts.includes(product.id)"
                    :color="home.featuredProducts.includes(product.id) ? 'primary' : ''"
                    class="mr-1"
                    variant="outlined"
                    color="primary"
                    filter
                  >

                    {{ product.name }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>

              <!-- Featured Services Selection -->
              <v-card-text>
                <h3>Featured Services</h3>
                <v-chip-group
                  v-model="home.featuredServices"
                  multiple
                  label="Featured Services"
                  class="mt-2"
                >
                  <v-chip
                    v-for="service in services"
                    :key="service.id"
                    @click="toggleService(service.id)"
                    :input-value="home.featuredServices.includes(service.id)"
                    :color="home.featuredServices.includes(service.id) ? 'primary' : ''"
                    class="mr-1"
                    variant="outlined"
                    filter
                    color="primary"
                  >
                    {{ service.name }}
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>

            <!-- Slides Management -->
            <h3>Slides</h3>
            <v-btn @click="addSlide" color="primary" small>Add Slide</v-btn>
            <div v-for="(slide, index) in home.slides" :key="index" class="mt-4">
              <v-text-field v-model="slide.title" label="Slide Title"></v-text-field>
              <v-text-field v-model="slide.subTitle" label="Slide Subtitle"></v-text-field>
              <v-text-field v-model="slide.buttonText" label="Button Text"></v-text-field>
              <v-text-field v-model="slide.action" label="action"></v-text-field>

              <v-file-input
                v-model="slide.image"
                label="Upload Slide Image"
                accept="image/*"
                @change="previewImage(index)"
              ></v-file-input>
              <img v-if="slide.preview" :src="slide.preview" alt="Preview" style="max-height: 100px;" />
              <v-btn @click="removeSlide(index)" color="red" small>Remove Slide</v-btn>
            </div>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="grey">Cancel</v-btn>
          <v-btn @click="submit" color="blue darken-1" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Data Table for Home Sections -->
    <v-data-table
      :headers="headers"
      :items="homeList"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editHome(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteHome(item.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Snackbar for Success -->
    <v-snackbar v-model="successSnackbar" color="green" timeout="3000">
      {{ successMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="successSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Snackbar for Errors -->
    <v-snackbar v-model="errorSnackbar" color="red" timeout="3000">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      fp: null,
      fs: null,
      dialog: false,
      valid: false,
      isEdit: false,
      home: {
        id: null,
        welcomeMessage: null,
        featuredProducts: [],
        featuredServices: [],
        slides: [] // Array to store slides
      },
      imagePreviews: [],
      headers: [
        { title: 'Welcome Message', value: 'welcomeMessage' },
        { title: 'Featured Products', value: 'featuredProducts' },
        { title: 'Featured Services', value: 'featuredServices' },
        { title: 'Actions', value: 'actions', sortable: false },
      ],
      homeList: [],
      products: [],
      services: [],
      successSnackbar: false,
      errorSnackbar: false,
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.isEdit = false;
      this.home = {
        id: null,
        welcomeMessage: null,
        featuredProducts: [],
        featuredServices: [],
        slides: []
      };
    },
    closeDialog() {
      this.dialog = false;
    },
    addSlide() {
      this.home.slides.push({
        title: '',
        subTitle: '',
        buttonText: '',
        action: '',
        image: null,
        preview: null,
      });
    },
    removeSlide(index) {
      this.home.slides.splice(index, 1);
    },
    previewImage(index) {
      const file = this.home.slides[index].image;
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.home.slides[index].preview = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async submit() {
      console.log(this.home.featuredProducts)
      console.log(this.home.featuredServices)
      alert(this.fp)
      // Prepare formData for submission, similar to before but now includes slides
      const formData = new FormData();
      formData.append('welcomeMessage', this.home.welcomeMessage);
      formData.append('featuredProducts', this.fp);
      formData.append('featuredServices', this.fs);

      // Create an array of slides
      const slidesArray = this.home.slides.map(slide => {
        const slideData = {
          title: slide.title,
          subTitle: slide.subTitle,
          buttonText: slide.buttonText,
          action: slide.action
        };
        console.log(slidesArray)
          // Append the image if it exists
         

          return slideData;
        });
        formData.append('slides', JSON.stringify(slidesArray));
        // Append images separately
  this.home.slides.forEach((slide, index) => {
    if (slide.image) {
      formData.append(`images`, slide.image); // Append image file
    }
  });
      // slidesArray.forEach(slide => {
      //   if (slide.images) {
      //     formData.append('slides', JSON.stringify(slide)); // Append slide data
      //     formData.append('images', slide.images); // Append image file
      //   }
      // });


      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      try {
        if (this.isEdit) {
          await axios.put(`/home/${this.home.id}`, formData, config);
          this.successMessage = 'Home section updated successfully!';
        } else {
          await axios.post('/home', formData, config);
          this.successMessage = 'Home section added successfully!';
        }
        this.successSnackbar = true;
        this.fetchHomeList();
        this.closeDialog();
      } catch (error) {
        this.errorMessage = 'An error occurred while saving the home section.';
        this.errorSnackbar = true;
      }
    },
    async fetchHomeList() {
      try {
        const response = await axios.get('/homes');
        this.homeList = response.data.homes || [];
      } catch (error) {
        this.errorMessage = 'Failed to fetch home sections.';
        this.errorSnackbar = true;
      }
    },
    async fetchProducts() {
      try {
        const response = await axios.get('/products');
        this.products = response.data;
      } catch (error) {
        this.errorMessage = 'Failed to fetch products.';
        this.errorSnackbar = true;
      }
    },
    async fetchServices() {
      try {
        const response = await axios.get('/services');
        this.services = response.data;
      } catch (error) {
        this.errorMessage = 'Failed to fetch services.';
        this.errorSnackbar = true;
      }
    },
    editHome(item) {
      this.home = { ...item };
      this.isEdit = true;
      this.dialog = true;
    },
    async deleteHome(id) {
      try {
        await axios.delete(`/home/${id}`);
        this.successMessage = 'Home section deleted successfully!';
        this.successSnackbar = true;
        this.fetchHomeList();
      } catch (error) {
        this.errorMessage = 'Failed to delete home section.';
        this.errorSnackbar = true;
      }
    },

    toggleProduct(id) {
    if (!Array.isArray(this.fp)) {
      this.fp = [];
    }

    const index = this.fp.indexOf(id);
    if (index !== -1) {
      this.fp.splice(index, 1);
    } else {
      this.fp.push(id);
    }
  },

  toggleService(id) {
    if (!Array.isArray(this.fs)) {
      this.fs = [];
    }

    const index = this.fs.indexOf(id);
    if (index !== -1) {
      this.fs.splice(index, 1);
    } else {
      this.fs.push(id);
    }
  },
},
  mounted() {
    this.fetchHomeList();
    this.fetchProducts();
    this.fetchServices();
  }
};
</script>
