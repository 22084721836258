import { createRouter, createWebHistory } from 'vue-router';
//import store from '../store'; // Import your Vuex store
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
// import Dashboard from '../views/Dashboard.vue';
// import Profile from '../views/Profile.vue';
// import Home from '../views/HomeView.vue';
// import GenerateLN from '../views/GenerateLotteryNumbers.vue';
// import PurchaseTicket from '../views/PurchaseTicket.vue';
// import Transaction from '@/views/Transaction.vue';
// import AllTransaction from '@/views/AllTransaction.vue';
// import MyTickets from '@/views/MyTickets.vue'
// import EditProfile from '@/views/EditProfile.vue';




import HomeM from '../views/Public/Home.vue';
import IndustriesI from '../views/Public/Industries.vue';
import ProductsI from '../views/Public/Products.vue';
import Services from '../views/Public/Services.vue';
import AboutI from '../views/Public/AboutM.vue';
import TestimoniesI from '../views/Public/Testimonies.vue';
import ContactI from '../views/Public/Contact.vue';
import AdminDashboard from '../views/Admin/AdminDashboard.vue';
import HomeManager from '../views/Admin/HomeManager.vue';
import IndustryManager from '../views/Admin/IndustryManager.vue';
import ProductManager from '../views/Admin/ProductManager.vue';
import ServiceManager from '../views/Admin/ServiceManager.vue';
import AboutManager from '../views/Admin/AboutManager.vue';
import TestimonyManager from '../views/Admin/TestimonyManager.vue';
import ContactManager from '../views/Admin/ContactManager.vue';

const routes = [

    { path: '/', component: HomeM },
    { path: '/industries', component: IndustriesI },
    { path: '/products', component: ProductsI },
    { path: '/services', component: Services },
    { path: '/about', component: AboutI },
    { path: '/testimonies', component: TestimoniesI },
    { path: '/contact', component: ContactI },
    
    { path: '/admin', component: AdminDashboard, children: [
      { path: 'home', component: HomeManager },
      { path: 'industries', component: IndustryManager },
      { path: 'products', component: ProductManager },
      { path: 'services', component: ServiceManager },
      { path: 'about', component: AboutManager },
      { path: 'testimonies', component: TestimonyManager },
      { path: 'contact', component: ContactManager },
    ]},




    { path: '/login', component: Login },
    { path: '/register', component: Register },
    // { path: '/generate-loto-number', component: GenerateLN },
    // { path: '/purchase-ticket', component: PurchaseTicket },


    // {
    //     path: '/my-tickets',
    //     component: MyTickets,
    //     meta: { requiresAuth: true }, // Protect this route
    // },
    // {
    //     path: '/edit-profile',
    //     name: 'EditProfile',
    //     component: EditProfile,
    //   },
    // {
    //     path: '/dashboard',
    //     component: Dashboard,
    //     meta: { requiresAuth: true }, // Protect this route
    // },
    // {
    //     path: '/bank-transaction',
    //     component: Transaction,
    //     meta: { requiresAuth: true }, // Protect this route
    // },
    // {
    //     path: '/all-transactions',
    //     component: AllTransaction,
    //     meta: { requiresAuth: true }, // Protect this route
    // },
    // {
    //     path: '/profile',
    //     component: Profile,
    //     meta: { requiresAuth: true }, // Protect this route
    // },
];

const router = createRouter({
    history: createWebHistory('/'), 
    routes,
});

// Global route guard
// Global route guard
// router.beforeEach((to, from, next) => {
//     const isAuthenticated = store.getters.isAuthenticated; // Use the getter

//     console.log(`Navigating to: ${to.path}`);
//     console.log(`Authenticated: ${isAuthenticated}`);

//     // Check if the route requires authentication
//     if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
//         next('/login'); // Redirect to login if not authenticated
//     } else {
//         next(); // Allow access
//     }
// });





export default router;
