<template>
  <v-container>
    <!-- Carousel for Hero Images -->
      <!-- Carousel Section -->
      <v-carousel
        hide-delimiter-background
        show-arrows
        cycle
        :interval="7000"
        height="500px"
        class="stylish-carousel"
      >
        <!-- Loop through carousel items -->
        <v-carousel-item
          v-for="(item, index) in carouselItems"
          :key="index"
          class="carousel-item"
        >
          <!-- Background Image -->
          <v-img
          :src="item.image"
          class="carousel-bg"
          max-height="650px"  
          min-height="200px"  
          width="100%"
          cover
        ></v-img>

          <!-- Overlay Text Content -->
          <div class="carousel-text-overlay">
            <v-col
              class="text-center d-flex flex-column align-center justify-center"
            >
              <!-- Title with fade-in animation -->
              <transition name="fade-slide" mode="out-in">
                <h1
                  v-if="item.title"
                  class="display-2 white--text font-weight-bold animated fadeInDown text-shadow"
                  :key="`title-${index}`"
                >
                <p style="color: white;" > {{ item.title }}</p>

                </h1>
              </transition>

              <!-- Subtitle with fade-in effect after title -->
              <transition name="fade-slide" mode="out-in">
                <h3 
                  v-if="item.subtitle"
                  class="white--text mb-4 text-shadow animated fadeIn delay-1s"
                  :key="`subtitle-${index}`"
                >
                 <p style="color: white;" >{{ item.subtitle }}</p> 
                </h3>
              </transition>

              <!-- Button with hover effect and animation -->
              <v-btn
                color="success"
                class="elevated-btn animated fadeInUp delay-2s stylish-button"
                @click="goToAction(item.action)"
              >
                {{ item.buttonText }}
              </v-btn>
            </v-col>
          </div>
        </v-carousel-item>
      </v-carousel>

    <!-- Welcome Message -->
    <v-row align="center" justify="center" class="mt-5">
      <v-col cols="12" md="12">
            <h3 class="heading">{{ welcomeMessage }}</h3>
      </v-col>
    </v-row>

    <!-- Featured Products (Waste Management Technology and Baking Ovens) -->
    <v-row class="mt-5 stylish-section">
    <v-col cols="12">
      <h2 class="heading text-center mb-8">Our Waste Incinerators</h2>
      <v-row justify="center">
        <v-col
          v-for="(product, index) in featuredProducts"
          :key="index"
          cols="12"
          sm="6"
          md="4"
        >
          <v-card class="hoverable stylish-card">
            <v-img
              :src="product.image"
              class="carousel-bg stylish-image"
              height="250px"
              cover
            >
              <!-- Gradient overlay -->
              <div class="image-overlay"></div>
            </v-img>

            <v-card-text class="text-center">
              <v-h3 class="product-title">{{ product.name }}</v-h3>
              <h5 class="product-description">{{ product.description }}</h5>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>

    <!-- Featured Services (Waste Management Solutions and Oven Maintenance) -->
    <!-- <v-row class="mt-5">
      <v-col cols="12">
        <h2 class="heading">Our Baking Ovens</h2>
        <v-row justify="center">
          <v-col v-for="(service, index) in featuredServices" :key="index" cols="12" sm="6" md="4">
            <v-card class="hoverable stylish-card" >
              <v-img
                :src="service.image"
                class="carousel-bg stylish-image"
                height="250px"
                cover
              >
                 Gradient overlay -->
                <!-- <div class="image-overlay"></div>
              </v-img>
             <div class="service-card">
    <h3 class="service-title">{{ service.name }}</h3>
    <h5 class="service-description">{{ service.description }}</h5>
  </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row> -->



    <v-row class="mt-5 stylish-section">
    <v-col cols="12" >
      <h2 class="heading text-center mb-8">Our Baking Ovens</h2>
      <v-row justify="center">
        <v-col
          v-for="(service, index) in featuredServices"
          :key="service.id"
          cols="12"
          sm="6"
          md="6"
        >
          <v-card class="hoverable stylish-card">
            <v-img
              :src="service.image"
              class="carousel-bg stylish-image"
              height="250px"
              cover
            >
              <div class="image-overlay"></div>
            </v-img>

            <v-card-text>
              <v-h3 class="service-title">{{ service.name }}</v-h3>

              <ul class="specifications">
                <li><strong>Power Consumption:</strong> {{ service.specifications.powerConsumption }}</li>
                <li><strong>Baking Capacity:</strong> {{ service.specifications.bakingCapacity }}</li>
                <li><strong>Baking Area:</strong> {{ service.specifications.bakingArea }}</li>
                <li><strong>Energy Sources:</strong> {{ service.specifications.energySources }}</li>
                <li><strong>Batteries:</strong> {{ service.specifications.batteries }}</li>
                <li><strong>Fuel Consumption:</strong> {{ service.specifications.fuelConsumption }}</li>
                <li><strong>Dimensions:</strong> {{ service.specifications.dimensions }}</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
    <!-- Testimonials Section -->
    <v-container class="mt-10 stylish-testimonials">
    <h3 class="text-center stylish-heading mb-8">What Our Clients Say</h3>
    
    <v-row justify="center">
      <v-col cols="12" md="10" sm="8">
        <v-carousel hide-delimiters interval="7000" cycle class="stylish-carousel" height="400">
          <v-carousel-item v-for="(testimonial, index) in testimonials" :key="index">
            <v-card class="testimonial-card text-center py-6 stylish-card" elevation="10">
              <v-avatar size="120" class="mx-auto mb-4 stylish-avatar" tile>
                <v-img :src="testimonial.avatar" class="avatar-img"></v-img>
              </v-avatar>
              <v-card-title class="testimonial-name mb-2 stylish-title">{{ testimonial.name }}</v-card-title>
              <v-card-subtitle class="testimonial-message mb-4 stylish-message">{{ testimonial.message }}</v-card-subtitle>
              <v-divider class="stylish-divider"></v-divider>
              <v-card-actions class="mt-4">
                <v-icon large class="testimonial-quote-icon stylish-icon">mdi-format-quote</v-icon>
              </v-card-actions>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
  </v-container>

  </v-container>
  <v-row justify="center">
    <v-col cols="12" md="10" sm="8">
      <h2 class="text-center">Our Location</h2>
      <p class="text-center">Find us at our main office located at the heart of the city.</p>

      <!-- Embedded Google Map -->
      <div class="map-embed">
        <iframe
          width="100%"
          height="400"
          frameborder="0"
          style="border:0"
          allowfullscreen
          aria-hidden="false"
          tabindex="0"
          :src="mapSrc"
        ></iframe>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HomeM',
  data() {
    return {
      homeList: [],
      mapSrc:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d241317.1160996746!2d-74.24559537703412!3d40.69767006872521!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDAlMzcJTiA3NMKwMDInRTQ!5e0!3m2!1sen!2sus!4v1609385894395!5m2!1sen!2sus",
      welcomeMessage: "Welcome to MICOTECH ENGINEERING",
      carouselItems: [
        {
          title: "Innovative Waste Management Technology",
          subtitle: "Leading the future in sustainable waste solutions.",
          buttonText: "Learn More",
          action: 'products',
          image: require("@/assets/mico/3.jpg")
        },
        {
          title: "Advanced Baking Ovens",
          subtitle: "State-of-the-art baking technology for every need.",
          buttonText: "View Ovens",
          action: 'products',
          image: require("@/assets/m/m8.jpg")
        },
        {
          title: "Custom Waste Solutions",
          subtitle: "Tailored solutions for businesses and communities.",
          buttonText: "view products",
          action: 'products',
          image: require("@/assets/mico/1.jpg")
        }
      ],
      featuredProducts: [
        { id: 1, name: 'MICO 00204', description: '• Ideal for smaller facilities • Capacity: 200kg per load.', image: require('@/assets/mico/6.jpg') },
        { id: 2, name: 'MICO 00207', description: '    • Suitable for medium to large-scale waste management • Handles larger volumes with enhanced efficiency.', image: require('@/assets/mico/5.jpg') },
      ],
      featuredServices: [
  {
    id: 1,
    name: 'Model MICO 00204/2008',
    specifications: {
      powerConsumption: '2kW at 220V',
      bakingCapacity: '432 breads in 10-12 minutes',
      bakingArea: '4.2 square meters (1.4m x 3m)',
      energySources: 'Wood, fuel, biogas, and oil',
      batteries: '12',
      fuelConsumption: '1.7 liters per hour',
      dimensions: '200mm (H) x 180mm (D) x 260mm (W)',
    },
    image: require('@/assets/m/m1.jpg'),
  },
  {
    id: 2,
    name: 'Model MICO 00205/2009',
    specifications: {
      powerConsumption: '2.2kW at 220V or 380V',
      bakingCapacity: '720 breads in 10-13 minutes',
      bakingArea: '6.48 square meters (2.16m x 3m)',
      energySources: 'Wood, fuel, biogas, and oil',
      batteries: '18',
      fuelConsumption: '2 liters per hour',
      dimensions: '220mm (H) x 180mm (D) x 300mm (W)',
    },
    image: require('@/assets/m/m2.jpg'),
  },
  {
    id: 3,
    name: 'Model MICO 00207/2013',
    specifications: {
      powerConsumption: '3kW at 220V or 380V',
      bakingCapacity: '864 breads in 10-13 minutes',
      bakingArea: '8.64 square meters (2.16m x 4m)',
      energySources: 'Wood, fuel, biogas, and oil',
      batteries: '24',
      fuelConsumption: '2.5 liters per hour',
      dimensions: '220mm (H) x 180mm (D) x 300mm (W)',
    },
    image: require('@/assets/m/m3.jpg'),
  },
  {
    id: 4,
    name: 'Model MICO 00206/2010',
    specifications: {
      powerConsumption: '3kW at 220V or 380V',
      bakingCapacity: '960 breads in 10-13 minutes',
      bakingArea: '9.2 square meters (2.3m x 4m)',
      energySources: 'Wood, fuel, biogas, and oil',
      batteries: '24',
      fuelConsumption: '3 liters per hour',
      dimensions: '220mm (H) x 180mm (D) x 300mm (W)',
    },
    image: require('@/assets/m/m4.jpg'),
  },
      ],
      testimonials: [
        {
          name: 'Aisha Tesfaye',
          position: 'Hospital Manager',
          message: 'MicoTech’s incinerators have significantly improved our waste management process. The efficiency is unmatched!',
          avatar: require('@/assets/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Hospital', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Kedir Abebe',
          position: 'Environmental Specialist',
          message: 'The air pollution control features of MicoTech systems give us peace of mind. We are doing our part for the environment.',
          avatar: require('@/assets/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Environment', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Selam Tesfamariam',
          position: 'Chief Engineer',
          message: 'The durability of MicoTech’s products is commendable. They have become our trusted partner in waste management.',
          avatar: require('@/assets/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Engineering', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Hanna Alemayehu',
          position: 'Baker',
          message: 'The baking ovens are fantastic! We can produce a large quantity of bread in no time.',
          avatar: require('@/assets/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Baking', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Mekonnen Gebrehiwet',
          position: 'Restaurant Owner',
          message: 'Our bakery’s efficiency has increased tremendously since we started using MicoTech ovens.',
          avatar: require('@/assets/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Restaurant', // Replace with actual image URLs
          link: '#'
        },
        {
          name: 'Abeba Kidane',
          position: 'Catering Manager',
          message: 'Highly recommend MicoTech! Their ovens and incinerators are both innovative and reliable.',
          avatar: require('@/assets/m1.jpg'), // Replace with actual image URLs
          backgroundImage: 'https://via.placeholder.com/400x200/ffffff/000000?text=Catering', // Replace with actual image URLs
          link: '#'
        }
      ]
    };
  },
  methods: {
    goToAction(action) {
      this.$router.push({ name: action });
    }
  }
};
</script>

<style scoped>
.stylish-carousel {
  border-radius: 8px;
  overflow: hidden;
}

.carousel-bg {
  opacity: 0.85;
}

.carousel-text-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 20px;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8);
}

.stylish-button {
  transition: all 0.3s ease;
}

.stylish-button:hover {
  transform: translateY(-3px);
}

.welcome-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
}

.welcome-card {
  box-shadow: none;
}

.stylish-heading {
  font-weight: bold;
}

.stylish-card {
  position: relative;
  overflow: hidden;
}

.stylish-image {
  transition: all 0.3s ease;
}

.stylish-card:hover .stylish-image {
  transform: scale(1.05);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8));
}

.stylish-testimonials {
  background-color: #f8f9fa;
  padding: 50px 0;
}

.testimonial-card {
  background-color: #ffffff;
}

.stylish-avatar {
  transition: all 0.3s ease;
}

.testimonial-quote-icon {
  color: #5c6bc0;
}

.map-embed {
  margin-top: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.heading {
  font-size: 2.5rem; /* Adjust the font size as needed */
  font-weight: 700; /* Make the font bold */
  text-align: center; /* Center the heading */
  color: #3f51b5; /* Set a stylish color */
  margin: 1.5rem 0; /* Add some vertical margin */
  position: relative; /* Required for the pseudo-elements */
  text-transform: uppercase; /* Transform text to uppercase */
  letter-spacing: 1px; /* Add spacing between letters */
}

.heading::after {
  content: ''; /* Creates a pseudo-element */
  display: block; /* Make it a block element */
  width: 60%; /* Width of the underline */
  height: 4px; /* Thickness of the underline */
  background-color: #094033; /* Color of the underline */
  margin: 0.5rem auto; /* Center it below the heading */
  border-radius: 2px; /* Rounded edges */
  transition: width 0.3s ease; /* Animation for hover effect */
}

.heading:hover::after {
  width: 100%; /* Change width on hover */
}

.heading:hover {
  color: #094033; /* Change text color on hover */
  transform: scale(1.05); /* Slightly enlarge the heading */
  transition: color 0.3s, transform 0.3s; /* Animation for color and scaling */
}


.stylish-section {
  background-color: #f8f9fa; /* Light background */
  padding: 40px;
  border-radius: 15px;
}

.heading {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #333;
  letter-spacing: 1px;
}

.stylish-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.stylish-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

.stylish-image {
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.product-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  color: #fff;
  margin-top: -50px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.product-description {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  color: #555;
  padding: 10px 0;
  margin-bottom: 20px;
  line-height: 1.6;
}

.stylish-section {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 15px;
}

.heading {
  font-family: 'Playfair Display', serif;
  font-weight: 700;
  font-size: 2.5rem;
  color: #333;
  letter-spacing: 1px;
}

.stylish-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
}

.stylish-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.2);
}

.stylish-image {
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.7));
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.service-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.8rem;
  font-weight: 600;
  color: #333;
  margin-top: 10px;
  text-align: center;
}

.specifications {
  list-style-type: none;
  padding: 0;
  margin-top: 15px;
  font-size: 1rem;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.6;
}

.specifications li {
  padding: 5px 0;
  color: #555;
}

.specifications strong {
  color: #08b1d7;
  font-weight: 600;
}
.stylish-testimonials {
  background-color: #f8f9fa;
  padding: 50px 20px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.stylish-heading {
  font-size: 2.5rem;
  font-weight: bold;
  color: #094033;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}

.stylish-carousel {
  border-radius: 15px;
  overflow: hidden;
}

.stylish-card {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.stylish-card:hover {
  transform: translateY(-10px);
}

.stylish-avatar {
  background-color: #094033;
  border-radius: 50%;
  border: 4px solid #ffffff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.avatar-img {
  object-fit: cover;
  border-radius: 50%;
}

.stylish-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #094033;
  letter-spacing: 0.5px;
}

.stylish-message {
  font-size: 1.2rem;
  color: #6c757d;
  font-style: italic;
}

.stylish-divider {
  background-color: #094033;
  height: 2px;
}

.stylish-icon {
  color: #094033;
  font-size: 2.5rem;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
