import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import axios from 'axios'

loadFonts()
axios.defaults.baseURL = 'http://192.168.0.117:9091/api'; 
//axios.defaults.baseURL = 'https://rnopz-196-190-32-0.a.free.pinggy.link/api'; 

axios.defaults.headers.common['Content-Type'] = 'application/json';
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
// Set the Authorization header if the token exists in localStorage
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
