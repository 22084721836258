<template>
  <v-container fluid>
    <v-row>
      <!-- Loop through each service and display it -->
      <v-col
        v-for="service in services"
        :key="service.id"
        cols="12"
        md="4"
        lg="3"
      >
        <!-- Service Card -->
        <v-card class="mx-auto my-5" elevation="3">
          <v-img
            :src="service.imageURL[0]"
            v-if="service.imageURL.length > 0"
          ></v-img>

          <v-card-title class="headline">
            {{ service.name }}
          </v-card-title>

          <v-card-text>
            <p class="truncate">{{ service.description }}</p>

            <!-- Related Industry -->
            <h4 class="mt-2">Industry: {{ service.industry.name }}</h4>
            <v-carousel
              hide-delimiters
              v-if="service.industry.imageURL.length > 0"
              height="150px"
              class="mt-2"
            >
              <v-carousel-item
                v-for="(image, index) in service.imageURL"
                :key="index"
                :src="`${'http://192.168.0.117:9091'}${image}`"
                ></v-carousel-item>
            </v-carousel>
          </v-card-text>

          <v-card-actions>
            <v-btn color="primary" @click="handleAction(service)">
              Learn More
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- Error Snackbar -->
    <v-snackbar v-model="errorSnackbar" color="red" timeout="3000">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      services: [], // Array to hold all services
      errorSnackbar: false,
      errorMessage: '',
    };
  },
  methods: {
    async fetchServices() {
      try {
        const response = await axios.get('/services'); // Modify API endpoint as needed
        this.services = response.data; // Assuming response contains an array of services
      } catch (error) {
        this.errorMessage = 'Failed to load services.';
        this.errorSnackbar = true;
      }
    },
    handleAction(service) {
      // Handle further action, such as navigating to a detailed service page
      console.log('Learn more about:', service.name);
    },
  },
  mounted() {
    this.fetchServices();
  },
};
</script>
<style scoped>
.v-card {
  transition: all 0.3s ease-in-out;
}

.v-card:hover {
  transform: scale(1.03);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.headline {
  color: #3E50B4;
  font-weight: bold;
  text-align: center;
}

p {
  font-size: 0.95rem;
  color: #555;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-btn {
  margin-left: auto;
  margin-right: auto;
}
</style>
