import axios from 'axios';
import { createStore } from 'vuex';

export default createStore({
    state: {
        token: localStorage.getItem('token') || null,
        user: JSON.parse(localStorage.getItem('user')) || null, // Initialize user as null
    },
    mutations: {
        SET_TOKEN(state, token) {
            state.token = token;
            localStorage.setItem('token', token); // Store token in localStorage
        },
        SET_USER(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
      
        CLEAR_USER(state) {
            state.user = null; // Reset user to null
            state.token = null;
            localStorage.removeItem('token'); // Remove token from localStorage
            localStorage.removeItem('user'); // Remove user from localStorage
        },
      
    },
    actions: {
       
        // async register({ commit }, userData) {
        //     const response = await axios.post('http://localhost:5000/api/register', userData);
        //     if (response.data.token) {
        //         commit('SET_TOKEN', response.data.token);
        //         await this.dispatch('fetchUser');
        //     }
        //     return response;
        // },
        
        async login({ commit }, userData) {
            console.log('Logging in with:', userData);
            const response = await axios.post('/login', userData);
            const token = response.data.token;
            const user = response.data.user; // Assuming the response includes user data
            console.log('Received token:', token);
            commit('SET_TOKEN', token);
            commit('SET_USER', user); // Make sure user is a valid object
        },
        
        async fetchUser({ commit }) {
            try {
                if(localStorage.getItem('user')) {
                    commit('SET_USER', JSON.parse(localStorage.getItem('user'))); // Assuming this returns a user object

                }
                //const response = await axios.get('http://localhost:5000/api/dashboard');
                //commit('SET_USER', response.data.user); // Assuming this returns a user object
            } catch (error) {
                console.error('Failed to fetch user:', error);
            }
        },
        
        logout({ commit }) {
            commit('CLEAR_USER'); // Clear user data and token
        },
    },
    getters: {
        user: (state) => state.user, // Add a getter for user
        isAuthenticated: (state) => !!state.token, // Additional getter for authentication state
    },
});
