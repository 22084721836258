<template>
    <v-app>
      <v-navigation-drawer app>
        <v-list dense>
          <v-list-item-group>
            <v-list-item to="/admin/home">
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
            <v-list-item to="/admin/industries">
              <v-list-item-title>Industries</v-list-item-title>
            </v-list-item>
            <v-list-item to="/admin/products">
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item>
            <v-list-item to="/admin/services">
              <v-list-item-title>Services</v-list-item-title>
            </v-list-item>
            <v-list-item to="/admin/about">
              <v-list-item-title>About</v-list-item-title>
            </v-list-item>
            <v-list-item to="/admin/testimonies">
              <v-list-item-title>Testimonies</v-list-item-title>
            </v-list-item>
            <v-list-item to="/admin/contact">
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
  
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </template>
  
  <script>
  export default {
    name: 'AdminDashboard',
  };
  </script>
  