<template>
  <v-container>
    <v-btn @click="openDialog" color="primary">Add Industry</v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ isEdit ? 'Edit Industry' : 'Add Industry' }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="industry.name"
              label="Name"
              required
            ></v-text-field>
            <v-textarea
              v-model="industry.description"
              label="Description"
              required
            ></v-textarea>
            
              <!-- Multiple Image Upload -->
            <v-file-input
              label="Upload Images"
              accept="image/*"
              multiple
              @change="handleImageUpload"
            ></v-file-input>
            <!-- Image Preview Section -->
            <div v-if="imagePreviews.length">
              <h4>Image Previews:</h4>
              <div v-for="(image, index) in imagePreviews" :key="index" class="preview-image">
                <img :src="image" alt="Preview" style="max-height: 100px;" />
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="grey">Cancel</v-btn>
          <v-btn @click="submit" color="blue darken-1" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="industryList"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editIndustry(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteIndustry(item.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Snackbar for success messages -->
    <v-snackbar v-model="successSnackbar" color="green" timeout="3000">
      {{ successMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="successSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Snackbar for error messages -->
    <v-snackbar v-model="errorSnackbar" color="red" timeout="3000">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      isEdit: false,
      industry: {
        id: null,
        name: '',
        description: '',
        images: [], // Store multiple images
      },
      imagePreviews: [], // Array to hold image previews

      headers: [
        { title: 'Name', value: 'name' },
        { title: 'Description', value: 'description' },
        { title: 'Actions', value: 'actions', sortable: false },
      ],
      industryList: [], // Initialize as an empty array
      successSnackbar: false,
      errorSnackbar: false,
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.isEdit = false;
      this.industry = {
        id: null,
        name: '',
        description: '',
        images: [], // Reset images on dialog open
      };
      this.imagePreviews = []; // Reset previews

    },
    closeDialog() {
      this.dialog = false;
    },
    // Handle image upload to generate previews
    handleImageUpload(event) {
      const files = event.target.files;

      if (files && files.length) {
        // Loop through each selected file and append to the previews array
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          this.industry.images.push(file); // Add to the image list
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreviews.push(e.target.result); // Add preview URL to array
          };

          reader.readAsDataURL(file); // Convert to base64
        }
      }
    },
    async submit() {
      const formData = new FormData();
      formData.append('name', this.industry.name);
      formData.append('description', this.industry.description);
     // Append all images to the FormData
     this.industry.images.forEach((image) => {
        formData.append(`images`, image);
      });
      console.log(this.industry.images)
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data', // Ensure the content type is set correctly
        },
      };

      try {
        if (this.isEdit) {
          await axios.put(`/industries/${this.industry.id}`, formData, config);
          this.successMessage = 'Industry section updated successfully!';
        } else {
          await axios.post('/industries', formData, config);
          this.successMessage = 'Industry section added successfully!';
        }
        this.successSnackbar = true; // Show success snackbar
        this.fetchIndustryList();
        this.closeDialog();
      } catch (error) {
        console.error('Error saving industry:', error);
        this.errorMessage = 'An error occurred while saving the industry section.';
        this.errorSnackbar = true; // Show error snackbar
      }
    },
    async fetchIndustryList() {
      try {
        const response = await axios.get('/industries');
        // Ensure that the response contains an array
        console.log(response.data)
        this.industryList = response.data.industries
      } catch (error) {
        console.error('Error fetching industry list:', error);
        this.errorMessage = 'Failed to fetch industry list.';
        this.errorSnackbar = true; // Show error snackbar
      }
    },
    editIndustry(item) {
      this.isEdit = true;
      this.industry = { ...item };
      this.dialog = true;
    },
    async deleteIndustry(id) {
      try {
        await axios.delete(`/industries/${id}`);
        this.successMessage = 'Industry section deleted successfully!';
        this.successSnackbar = true; // Show success snackbar
        this.fetchIndustryList();
      } catch (error) {
        console.error('Error deleting industry:', error);
        this.errorMessage = 'An error occurred while deleting the industry section.';
        this.errorSnackbar = true; // Show error snackbar
      }
    },
  },
  mounted() {
    this.fetchIndustryList();
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
