<template>
  <v-container>
    <v-progress-linear
      v-if="loading"
      :value="progress"
      height="20"
      color="blue"
      striped
      indeterminate
    ></v-progress-linear>
    <v-btn @click="openDialog" color="primary">Add Product</v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>{{ isEdit ? 'Edit Product' : 'Add Product' }}</v-card-title>

        <v-card-text>
       
             <v-form ref="form" v-model="valid" v-if="industryList">
              <v-select
  v-model="product.industryID"
  :items="industryList.length ? industryList : []"
  label="Select Industry"
  item-title="name"
  item-value="id"
  required
></v-select>

            <v-text-field
              v-model="product.name"
              label="Product Name"
              required
            ></v-text-field>
            <v-textarea
              v-model="product.description"
              label="Product Description"
              required
            ></v-textarea>
            <v-text-field
              v-model="product.price"
              label="Price"
              type="number"
              required
            ></v-text-field>
            <v-select
              v-model="product.availabilityStatus"
              :items="['Available', 'Out of Stock']"
              label="Availability Status"
              required
            ></v-select>
            <!-- Multiple Image Upload -->
            <v-file-input
              label="Upload Images"
              accept="image/*"
              multiple
              @change="handleImageUpload"
            ></v-file-input>
            <!-- Image Preview Section -->
            <div v-if="imagePreviews.length">
              <h4>Image Previews:</h4>
              <div v-for="(image, index) in imagePreviews" :key="index" class="preview-image">
                <img :src="image" alt="Preview" style="max-height: 100px;" />
              </div>
            </div>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="grey">Cancel</v-btn>
          <v-btn @click="submit" color="blue darken-1" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="productList"
      item-key="id"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editProduct(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteProduct(item.id)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Snackbar for success messages -->
    <v-snackbar v-model="successSnackbar" color="green" timeout="3000">
      {{ successMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="successSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Snackbar for error messages -->
    <v-snackbar v-model="errorSnackbar" color="red" timeout="3000">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      loading: false,

      dialog: false,
      valid: false,
      isEdit: false,
      product: {
        id: null,
        industryID: null,
        name: '',
        description: '',
        price: null,
        images: [],
        availabilityStatus: '',
      },
      imagePreviews: [],
      headers: [
        { title: 'Product Name', value: 'name' },
        { title: 'Price', value: 'price' },
        { title: 'Availability', value: 'availabilityStatus' },
        { title: 'Actions', value: 'actions', sortable: false },
      ],
      productList: [],  // Ensure productList is initialized as an array
      industryList: [], // Ensure industryList is initialized as an array
      successSnackbar: false,
      errorSnackbar: false,
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.isEdit = false;
      this.product = {
        id: null, 
        industryID: null,
        name: '',
        description: '',
        price: null,
        images: [],
        availabilityStatus: '',
      };
      this.imagePreviews = [];
    },
    closeDialog() {
      this.dialog = false;
    },
    handleImageUpload(event) {
      const files = event.target.files;
      if (files && files.length) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          this.product.images.push(file);
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imagePreviews.push(e.target.result);
          };
          reader.readAsDataURL(file);
        }
      }
    },
    async submit() {
      const formData = new FormData();
formData.append('industryID', this.product.industryID || '');
formData.append('name', this.product.name || '');
formData.append('description', this.product.description || '');
formData.append('price', this.product.price || '');
formData.append('availabilityStatus', this.product.availabilityStatus || '');

this.product.images.forEach((image) => {
  formData.append('images', image);
});


      this.product.images.forEach((image) => {
        formData.append(`images`, image);
      });

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      try {
        if (this.isEdit) {
          await axios.put(`/products/${this.product.id}`, formData, config);
          this.successMessage = 'Product updated successfully!';
        } else {
          await axios.post('/products', formData, config);
          this.successMessage = 'Product added successfully!';
        }
        this.successSnackbar = true;
        this.fetchProductList();
        this.closeDialog();
      } catch (error) {
        console.error('Error saving product:', error);
        this.errorMessage = 'An error occurred while saving the product.';
        this.errorSnackbar = true;
      }
    },
    async fetchProductList() {
  try {
    const response = await axios.get('/products');
    this.productList = response.data; // Ensure it's an array
    console.log(response.data)
  } catch (error) {
    console.error('Error fetching product list:', error);
    this.errorMessage = 'Failed to fetch product list.';
    this.errorSnackbar = true;
  }
},

async fetchIndustryList() {
  try {
    this.loading = true;
    const response = await axios.get('/industries');
    this.industryList =  response.data.industries; // Ensure it's an array
  } catch (error) {
    console.error('Error fetching industry list:', error);
    this.errorMessage = 'Failed to fetch industry list.';
    this.errorSnackbar = true;
  } finally {
    this.loading = false;
  }
},

    editProduct(item) {
      this.isEdit = true;
      this.product = { ...item };
      this.dialog = true;
    },
    async deleteProduct(id) {
      try {
        await axios.delete(`/products/${id}`);
        this.successMessage = 'Product deleted successfully!';
        this.successSnackbar = true;
        this.fetchProductList();
      } catch (error) {
        console.error('Error deleting product:', error);
        this.errorMessage = 'An error occurred while deleting the product.';
        this.errorSnackbar = true;
      }
    },
  },
  mounted() {
    this.fetchProductList();
    this.fetchIndustryList();
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
