<template>
  <v-container>
    <v-row justify="center" align="center" style="min-height: 100vh;">
      <v-col cols="12" sm="8" md="6">
        <v-card elevation="10">
          <v-card elevation="15" color="#094033">
            <v-card-title class="headline">Login</v-card-title>
          </v-card>
          <v-card-text>
            <v-form ref="form" v-model="valid" @submit.prevent="handleLogin">
              <v-text-field
                v-model="loginIdentifier"
                label="Username"
                :rules="loginRules"
                required
                clearable
                variant="outlined"
              ></v-text-field>
              <v-text-field
                v-model="password"
                label="Password"
                type="password"
                :rules="passwordRules"
                required
                clearable
                variant="outlined"
              ></v-text-field>
              <v-btn type="submit" 
              color="#094033" 
              class="mt-4 align-center justify-center"
              aria-label="Login"
              >
              <v-icon left>mdi-login</v-icon>
              Login
          </v-btn>

            </v-form>
            <!-- Additional prompt for new users -->
            <v-row justify="center" class="mt-4">
              <v-col>
                <!-- <p class="text-center">
                  Not registered yet? 
                  <span @click="goToRegister" class="login-link">Register</span>
                </p> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Snackbar for success or error messages -->
    <v-snackbar v-model="snackbar" :timeout="3000" top>
      {{ snackbarMessage }}
      <template #action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: 'UserLogin',
  data() {
    return {
      loading: false,
      valid: false,
      loginIdentifier: '', // This will hold either phone or email
      password: '',
      snackbar: false,
      snackbarMessage: '',
      // Validation rules
      loginRules: [
        (v) => !!v || 'Email is required',
        (v) => /^\d+$/.test(v) || /.+@.+\..+/.test(v) || 'Must be a valid email',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => v.length >= 6 || 'Password must be at least 6 characters long',
      ],
    };
  },
  methods: {
    async handleLogin() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const userData = {
            username: this.loginIdentifier, // Send as identifier
            password: this.password,
          };
          await this.$store.dispatch('login',userData );
          this.snackbarMessage = 'Login successful!';
          this.snackbar = true;
          this.$router.push('/admin'); // Redirect to dashboard or home after login
        } catch (error) {
          this.snackbarMessage = error.response.data.message || 'Login failed!';
          this.snackbar = true;
          
        } finally {
          this.loading = false;
        }
      }
    },
    // goToRegister() {
    //   this.$router.push('/register'); // Navigate to the registration page
    // },
  },
};
</script>

<style scoped>
.v-card {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
.login-link {
  color: blue; /* Change color as needed */
  cursor: pointer; /* Indicate that it is clickable */
  text-decoration: underline; /* Underline for a link effect */
}
</style>
