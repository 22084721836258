<template>
  <v-container>
    <v-btn @click="openDialog" color="primary">Add Contact</v-btn>

    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ isEdit ? 'Edit Contact' : 'Add Contact' }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-text-field
              v-model="contact.name"
              label="Name"
              required
            ></v-text-field>
            <v-text-field
              v-model="contact.email"
              label="Email"
              required
              type="email"
            ></v-text-field>
            <v-text-field
              v-model="contact.phoneNumber"
              label="Phone Number"
              required
            ></v-text-field>
            <v-textarea
              v-model="contact.message"
              label="Message"
              required
            ></v-textarea>
           
            <v-select
              v-model="contact.status"
              :items="['New', 'In Progress', 'Resolved']"
              label="Status"
              required
            ></v-select>
            <v-text-field
              v-model="contact.addressLine1"
              label="Address Line 1"
              required
            ></v-text-field>
            <v-text-field
              v-model="contact.addressLine2"
              label="Address Line 2"
              required
            ></v-text-field>
            <v-text-field
              v-model="contact.city"
              label="City"
              required
            ></v-text-field>
            <v-text-field
              v-model="contact.state"
              label="State"
              required
            ></v-text-field>
            <v-text-field
              v-model="contact.country"
              label="Country"
              required
            ></v-text-field>

          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog" color="grey">Cancel</v-btn>
          <v-btn @click="submit" color="blue darken-1" :disabled="!valid">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-data-table
      :headers="headers"
      :items="contactList"
      item-key="_id"
      class="elevation-1"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon @click="editContact(item)">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <v-btn icon @click="deleteContact(item)">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- Snackbar for success messages -->
    <v-snackbar v-model="successSnackbar" color="green" timeout="3000">
      {{ successMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="successSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <!-- Snackbar for error messages -->
    <v-snackbar v-model="errorSnackbar" color="red" timeout="3000">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      dialog: false,
      valid: false,
      isEdit: false,
      contact: {
        id: null,
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        status: '',
        addressLine1: '',
        addressLine2:'',
        city: '',
        state: '',
        country: '',      },
      headers: [
        { title: 'Name', value: 'name' },
        { title: 'Email', value: 'email' },
        { title: 'Phone Number', value: 'phoneNumber' },
        { title: 'Status', value: 'status' },
        {title: 'Address Line 1', value: 'addressLine1'},
        {title: 'Address Line 2', value: 'addressLine2'},
        {title: 'City', value: 'city'},
        {title: 'State', value: 'state'},
        {title: 'Country', value: 'country'},
        { title: 'Actions', value: 'actions', sortable: false },
      ],
      contactList: [],
      successSnackbar: false,
      errorSnackbar: false,
      successMessage: '',
      errorMessage: '',
    };
  },
  methods: {
    openDialog() {
      this.dialog = true;
      this.isEdit = false;
      this.contact = {
        id: null,
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        status: '',
        addressLine1: '',
        addressLine2:'',
        city: '',
        state: '',
        country: '',
      };
    },
    closeDialog() {
      this.dialog = false;
    },
    async submit() {
      try {
        if (this.isEdit) {
          await axios.put(`/contact/${this.contact._id}`, this.contact);
          this.successMessage = 'Contact updated successfully!';
        } else {
          await axios.post('/contact', this.contact);
          this.successMessage = 'Contact added successfully!';
        }
        this.successSnackbar = true;
        this.fetchContactList();
        this.closeDialog();
      } catch (error) {
        console.error('Error saving contact:', error);
        this.errorMessage = 'An error occurred while saving the contact.';
        this.errorSnackbar = true;
      }
    },
    async fetchContactList() {
      try {
        const response = await axios.get('/contacts');
        this.contactList = response.data || []; // Adjust according to the actual structure
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching contact list:', error);
        this.errorMessage = 'Failed to fetch contact list.';
        this.errorSnackbar = true;
      }
    },
    editContact(item) {
      this.isEdit = true;
      this.contact = { ...item };
      this.dialog = true;
    },
    async deleteContact(item) {
      try {
        await axios.delete(`/contact/${item.ID}`);
        this.successMessage = 'Contact deleted successfully!';
        this.successSnackbar = true;
        this.fetchContactList();
      } catch (error) {
        console.error('Error deleting contact:', error);
        this.errorMessage = 'An error occurred while deleting the contact.';
        this.errorSnackbar = true;
      }
    },
  },
  mounted() {
    this.fetchContactList();
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
