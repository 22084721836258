<template>
  <v-app-bar app class="link-item" dense>
    <v-avatar
    size="40">
      <v-img src="@/assets/m1.jpg" alt="Avatar"></v-img>
    </v-avatar>
    <v-toolbar-title @click="navigate" class="text-white" v-if="$vuetify.display.mdAndUp">MicoTech Engineering</v-toolbar-title>
     <v-toolbar-title @click="navigate" class="text-white" v-else>MicoTech </v-toolbar-title>

    <v-spacer></v-spacer>

    <div class="d-flex align-center d-none d-md-flex" v-if="$vuetify.display.mdAndUp">
      <v-list class="d-flex flex-direction-row link-item">
        <router-link v-if="!isAuthenticated" to="/" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link v-if="!isAuthenticated" to="/about" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item>
        </router-link>
       
        <router-link v-if="!isAuthenticated" to="/products" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item>
        </router-link>
        
        <router-link v-if="!isAuthenticated" to="/testimonies" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Testimonies</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link v-if="!isAuthenticated" to="/contact" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </router-link>
        <!-- <router-link v-if="isAuthenticated" to="/admin" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Admin dashboard</v-list-item-title>
          </v-list-item>
        </router-link> -->
        <router-link v-if="isAuthenticated" to="/admin/home" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link v-if="isAuthenticated" to="/admin/about" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link v-if="isAuthenticated" to="/admin/industries" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Industries</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link v-if="isAuthenticated" to="/admin/products" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Products</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link v-if="isAuthenticated" to="/admin/services" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Services</v-list-item-title>
          </v-list-item>
        </router-link>
        <router-link v-if="isAuthenticated" to="/admin/testimonies" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Testimonies</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link v-if="isAuthenticated" to="/admin/contact" exact-active-class="active-link">
          <v-list-item class="text-white">
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </router-link>
      </v-list>

      <v-avatar size="60" @click="toggleMenu" class="ml-3" style="cursor: pointer;" v-if="isAuthenticated">
        <v-icon>mdi-account-circle</v-icon>
      </v-avatar>
      <v-menu v-model="menu" offset-y>
        <template #activator="{ props }">
          <div v-bind="props"></div>
        </template>
        <v-list v-if="isAuthenticated">
          <v-list-item>
            <v-card class="mx-auto" outlined elevation="2" max-width="500" min-width="100">
              <v-card-title>
                <v-avatar class="mr-3" size="40">
                  <v-icon large color="primary">mdi-account-circle</v-icon>
                </v-avatar>
                <span v-if="user" class="text-h8 font-weight-bold">
                  Welcome, {{ user }}
                </span>
              </v-card-title>

              <v-card-subtitle>
                <div class="d-flex justify-center">
                  <v-chip
                    v-if="user"
                    class="ma-2 text-center"
                    color="deep-purple lighten-1"
                    text-color="white"
                    outlined
                  >
                    {{ "Admin" }}
                  </v-chip>
                </div>
              </v-card-subtitle>
            </v-card>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item>
            
          </v-list-item>
          <v-list-item>
           
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item @click="goToProfile">
            <v-icon left>mdi-account</v-icon>
            Profile
          </v-list-item>
          <v-list-item @click="goToSettings">
            <v-icon left>mdi-cog</v-icon>
            Settings
          </v-list-item>
          <v-list-item @click="logout">
            <v-icon left>mdi-logout</v-icon>
            Logout
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <v-btn icon @click="toggleDrawer" class="d-md-none">
      <v-icon>mdi-menu</v-icon>
    </v-btn>
  </v-app-bar>




  <v-navigation-drawer
    v-model="drawer"
    app
    temporary
    dark
  >
    <v-list dense>
      <!-- Title -->
      <v-list-item class="pa-4" >
        <v-list-item-content>
          <v-list-item-title class="white--text text-h6 font-weight-bold">Main Menu</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Home Link -->
      <router-link to="/" exact-active-class="active-link" v-if="!isAuthenticated">
        <v-list-item>
          <v-list-item-content>

        <v-list-item-title class="white--text">Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </router-link>

      <!-- Unauthenticated Links -->
      <router-link v-if="!isAuthenticated" to="/about">
        <v-list-item>
          <v-list-item-title class="white--text">About Us</v-list-item-title>
        </v-list-item>
      </router-link>

     

      <router-link v-if="!isAuthenticated" to="/products" exact-active-class="active-link">
        <v-list-item>
          <v-list-item-title class="white--text">Products</v-list-item-title>
        </v-list-item>
      </router-link>

      

      <router-link v-if="!isAuthenticated" to="/testimonies" exact-active-class="active-link">
        <v-list-item>
          <v-list-item-title class="white--text">Testimonies</v-list-item-title>
        </v-list-item>
      </router-link>

      <router-link v-if="!isAuthenticated" to="/contact" exact-active-class="active-link">
        <v-list-item>
          <v-list-item-title class="white--text">Contact Us</v-list-item-title>
        </v-list-item>
      </router-link>

     

      
    </v-list>
  </v-navigation-drawer>
</template>



<script>
export default {
  data() {
    return {
      drawer: false,
      menu: false,
      menuD: false,
      isAuthenticated: false, // Replace with actual auth logic
      userName: '', // Replace with actual user name
      balance: 0, // Replace with actual balance
      user: null, // Replace with actual user object
    };
  },
  
  created() {
    this.isAuthenticated = false;
    this.user = localStorage.getItem('user');
  },
  methods: {
    navigate() {
        this.$router.push(this.isAuthenticated ? '/admin' : '/');
    },
    toggleDrawer() {
      this.drawer = !this.drawer;

    },
    toggleMenu() {
      this.menu = !this.menu;
    },
    toggleMenuD() {
      this.menuD = !this.menuD;

    },
    goToHome() {
      this.$router.push('/');
    },
    goToProfile() {
      this.$router.push('/profile');
    },
    goToSettings() {
      this.$router.push('/settings');
    },
    logout() {
      // Your logout logic here
      this.isAuthenticated = false; // Update your auth state
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
  },
};
</script>

<style scoped>
.link-item {
  background-color: #265570; /* Set background color here */
  border-radius: 5px; /* Optional: Add border radius for a rounded effect */
  margin: 0 5px; /* Optional: Add margin for spacing between links */
}

.link-item:hover {
  background-color: #056173; /* Optional: Change color on hover */
}
/* Active link style */
.active-link {
  color: #172b3c; /* Ensure the text color contrasts with the background */
  font-weight: bold;
}
.active-link {
  background-color:#06183e;
}
</style>
