<template>
  <v-container fluid>
    <v-row>
      <v-col
        v-for="industry in industries"
        :key="industry.id"
        cols="12"
        md="4"
      >
        <v-card class="mx-auto" elevation="2">
          <v-img
            :src="`${'http://192.168.0.117:9091'}${industry.imageURL[0]}`"
            height="200px"
            class="white--text"
            v-if="industry.imageURL.length > 0"
          >
            <v-card-title>{{ industry.name }}</v-card-title>
          </v-img>
          <v-card-text>
            <h3>{{ industry.name }}</h3>
            <p>{{ industry.description }}</p>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="viewIndustry(industry.id)">
              View Details
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="errorSnackbar" color="red" timeout="3000">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="errorSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      industries: [],
      errorSnackbar: false,
      errorMessage: '',
    };
  },
  methods: {
    async fetchIndustries() {
      try {
        const response = await axios.get('/industries');
        this.industries = response.data.industries || [];
      } catch (error) {
        console.error('Error fetching industries:', error);
        this.errorMessage = 'Failed to fetch industries.';
        this.errorSnackbar = true;
      }
    },
    viewIndustry(id) {
      // Navigate to industry details or perform an action
      console.log('Viewing industry:', id);
      // Implement navigation or further actions here
    },
  },
  mounted() {
    this.fetchIndustries();
  },
};
</script>
<style scoped>
.v-card {
  transition: transform 0.3s;
}

.v-card:hover {
  transform: scale(1.05);
}

h3 {
  font-weight: bold;
  color: #3E50B4;
}

.v-card-text p {
  font-size: 0.9rem;
  color: #555;
}

.v-btn {
  font-weight: bold;
}
</style>
